import React, { useEffect, useRef, useState } from "react";
import { Table } from "antd";
import { useSnackbar } from "notistack";
import { FilterDataOnFilteredResults } from "../../../../../servies/services";
import NumberComp2 from "../../../../../components/NumberComp2";

const Performance = ({
  selectedPrePayments,
  selectedDefaults,
  selectedLosses,
  selectedCreditMetrics,
  selectedDelinquencies,
  selectedCashflows,
  selectedDeals,
  startDate: propStartDate,
  endDate: propEndDate,
}) => {
  const [getLoansLoader, setGetLoansLoader] = useState(false);
  const [tableDataPrepayments, setTableDataPrepayments] = useState([]);
  const [tableKeys, setTableKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]); // New state to manage expanded rows
  const { enqueueSnackbar } = useSnackbar();

  const tableRef = useRef(null);

  const formatDate = (date) => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const startDate = formatDate(propStartDate);
  const endDate = formatDate(propEndDate);

  const selectSpecificPrePayemnts = selectedPrePayments.map(
    (item) => item.label
  );
  const selectSpecificDefaults = selectedDefaults.map((item) => item.label);
  const selectSpecificLosses = selectedLosses.map((item) => item.label);
  const selectSpecificCreditMetrics = selectedCreditMetrics.map(
    (item) => item.label
  );
  const selectSpecificDelinquencies = selectedDelinquencies.map(
    (item) => item.label
  );
  const selectSpecificCashflows = selectedCashflows.map((item) => item.label);

  const selectDeals =
    selectedDeals && selectedDeals.length > 0 ? selectedDeals[0].label : [];

  const filterDataOnFilteredResults = async () => {
    const data = {
      userName: sessionStorage.getItem("user_name"),
      dealName: selectDeals,
      startDate: startDate,
      endDate: endDate,
      cprVar: selectSpecificPrePayemnts,
      cdrVar: selectSpecificDefaults,
      lossesVar: selectSpecificLosses,
      creditVar: selectSpecificCreditMetrics,
      accVar: selectSpecificDelinquencies,
      cashflowVar: selectSpecificCashflows,
    };

    setGetLoansLoader(true);
    setLoading(true);

    try {
      const APIResponse = await FilterDataOnFilteredResults(data);
      if (APIResponse.status === 200) {
        const parsedResult = APIResponse.data.result.map((item) => ({
          date: item.id,
          type: item.colname,
          value: item.colvalue,
          category: item.par,
        }));

        const groupedData = parsedResult.reduce((acc, item) => {
          if (!acc[item.category]) acc[item.category] = {};
          if (!acc[item.category][item.type])
            acc[item.category][item.type] = {};
          acc[item.category][item.type][item.date] = item.value;
          return acc;
        }, {});

        const tableKeys = Object.keys(groupedData);
        const uniqueDates = Array.from(
          new Set(parsedResult.map((item) => item.date))
        ).sort((a, b) => new Date("1-" + a) - new Date("1-" + b));

        setDates(uniqueDates);
        setTableDataPrepayments(groupedData);
        setTableKeys(tableKeys);

        // Set all rows to be expanded by default
        const expandedKeys = constructMainTableData().map((item) => item.key);
        setExpandedRowKeys(expandedKeys);

        setGetLoansLoader(false);
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setGetLoansLoader(false);
      setLoading(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    if (
      selectedPrePayments.length > 0 ||
      selectedDefaults.length > 0 ||
      selectedLosses.length > 0 ||
      selectedCreditMetrics.length > 0 ||
      selectedDelinquencies.length > 0 ||
      selectedCashflows.length > 0
    ) {
      const timeoutId = setTimeout(() => {
        filterDataOnFilteredResults();
      }, 0); // Small delay to ensure multiple changes are captured simultaneously

      return () => clearTimeout(timeoutId); // Clean up the timeout if the component unmounts
    }
  }, [
    selectedPrePayments,
    selectedDefaults,
    selectedLosses,
    selectedCreditMetrics,
    selectedDelinquencies,
    selectedCashflows,
    startDate,
    endDate,
  ]);

  // useEffect(() => {
  //   if (selectedPrePayments.length > 0) filterDataOnFilteredResults();
  // }, [selectedPrePayments, startDate, endDate]);

  // useEffect(() => {
  //   if (selectedDefaults.length > 0) filterDataOnFilteredResults();
  // }, [selectedDefaults, startDate, endDate]);

  // useEffect(() => {
  //   if (selectedLosses.length > 0) filterDataOnFilteredResults();
  // }, [selectedLosses, startDate, endDate]);

  // useEffect(() => {
  //   if (selectedCashflows.length > 0) filterDataOnFilteredResults();
  // }, [selectedCashflows, startDate, endDate]);

  // useEffect(() => {
  //   if (selectedCreditMetrics.length > 0) filterDataOnFilteredResults();
  // }, [selectedCreditMetrics, startDate, endDate]);

  // useEffect(() => {
  //   if (selectedDelinquencies.length > 0) filterDataOnFilteredResults();
  // }, [selectedDelinquencies, startDate, endDate]);

  const columns = [
    {
      title: "",
      dataIndex: "metric",
      key: "metric",
      width: 150,
      fixed: "left",
      render: (text) => <span>{text}</span>,
    },
    ...dates.map((date) => ({
      title: date,
      dataIndex: date,
      key: date,
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            textAlign: "center", // Adjust as needed
          },
        };
      },
      render: (text) => {
        const isTextUndefined =
          text === undefined || text === null || text === "";

        const isWholeNumber = /^[+-]?[1-9]\d*$/.test(text);
        const isDecimal =
          /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text) ||
          /^[+-]?\d+\.\d+$/.test(text);

        const alignmentStyle = {
          textAlign:
            isTextUndefined || isWholeNumber || isDecimal
              ? "right"
              : text && text.includes("%")
              ? "center"
              : "left",
        };

        return (
          <div style={alignmentStyle}>
            <NumberComp2 value={text} />
          </div>
        );
      },
    })),
  ];

  const constructMainTableData = () => {
    const tableData = [
      {
        key: "1",
        metric: "Prepayments",
        isExpandable: selectedPrePayments.length > 0,
      },
      {
        key: "2",
        metric: "Default",
        isExpandable: selectedDefaults.length > 0,
      },
      { key: "3", metric: "Losses", isExpandable: selectedLosses.length > 0 },
      {
        key: "4",
        metric: "Credit Metrics",
        isExpandable: selectedCreditMetrics.length > 0,
      },
      {
        key: "5",
        metric: "Delinquencies",
        isExpandable: selectedDelinquencies.length > 0,
      },
      {
        key: "6",
        metric: "Cash Flows",
        isExpandable: selectedCashflows.length > 0,
      },
    ];

    return tableData.filter((item) => item.isExpandable);
  };

  const mainTableData = constructMainTableData();

  const createExpandableData = (category) => {
    const categoryData = tableDataPrepayments[category];
    if (!categoryData) return [];

    return Object.keys(categoryData).map((metric, index) => {
      const dateValues = dates.reduce((acc, date) => {
        acc[date] = categoryData[metric][date] || null;
        return acc;
      }, {});

      return {
        key: `${category}-${metric}-${index + 1}`,
        metric,
        ...dateValues,
      };
    });
  };

  const expandableRowRender = (record) => {
    const dataMapping = {
      Prepayments: createExpandableData("PrePayments"),
      Default: createExpandableData("Default"),
      Losses: createExpandableData("Losses"),
      "Credit Metrics": createExpandableData("Credit Metrics"),
      Delinquencies: createExpandableData("Delinquencies"),
      "Cash Flows": createExpandableData("Cash Flows"),
    };

    const dataSource = dataMapping[record.metric];

    return dataSource && dataSource.length > 0 ? (
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        showHeader={false}
        // className="checkPerformance"
        scroll={handleHorizontalScroll} // Set scroll for inner table
        rowKey="key"
        onRow={(record) => ({
          onClick: () => {
            console.log(record); // Handle row click
          },
        })}
      />
    ) : null;
  };

  const handleHorizontalScroll = (e) => {
    const headerTable = tableRef.current.querySelector(".ant-table-header");
    if (headerTable) {
      headerTable.scrollLeft = e.target.scrollLeft;
    }
  };

  return (
    <div ref={tableRef}>
      <Table
        className="performance-table"
        columns={columns}
        dataSource={mainTableData}
        loading={loading}
        expandable={{
          expandedRowRender: expandableRowRender,
          expandedRowKeys: expandedRowKeys, // Set expanded row keys
          onExpand: (expanded, record) => {
            const keys = expanded
              ? [...expandedRowKeys, record.key]
              : expandedRowKeys.filter((key) => key !== record.key);
            setExpandedRowKeys(keys);
          },
        }}
        onScroll={handleHorizontalScroll} // Attach scroll handler
        scroll={{ y: "calc(100vh - 400px)" }} // Set scroll for outer table
        pagination={false}
      />
    </div>
  );
};

export default Performance;
