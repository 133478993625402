import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSnackbar } from "notistack";
import Sidebar1 from "../../../../../components/sidebar/sidebar.js";
import { withSnackbar } from "notistack";
import {
  GetDataByPaymentDatesDeal,
  GetDataByDefaultValue,
  GetDataByUpdateAsset,
  GetDataByUpdateIssuer,
  GetDataByUpdateLoans,
  GetDataByUpdateLoansDetails,
  GetDataByTableChartDataDeal,
} from "../../../../../servies/services.js";
import LinearLoader from "../../../../../components/loader/LinearLoader.js";
import PerformanceDropDowns from "./PerformanceDropDowns.js";
import DataDisplaySummary from "./DataDisplaySummary.js";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ChatAssistant from "./ChatAssistant.js";
import IDA from "../../../../../images/IDA.svg";
import BETA from "../../../../../images/BETA.svg";
import RedFlag from "../../../../../images/RedFlag.svg";
import ThumbUp from "../../../../../images/thumb_up_alt.svg";
import ThumbDown from "../../../../../images/thumb_down_alt.svg";
import Back_Icon from "../../../../../images/Back_Icon.svg";
import { useHistory } from "react-router-dom";
import { Table, Card, Row, Col, Spin, Input } from "antd";
import "./DealAnalyticsLoans.css";
import LoanDetailsAnalytics from "./LoanDetailsAnalytics.js";
import { debounce } from "lodash";
import { CloseOutlined } from "@ant-design/icons";

// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

const DealAnalyticsLoans = (props) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [getLoansLoader, setGetLoansLoader] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [startDate, setStartDate] = useState(new Date("2022-01-01"));
  const [endDate, setEndDate] = useState(new Date("2024-07-25"));
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [assetClassOptions, setAssetClassOptions] = useState([]);
  const [selectedAssetClass, setSelectedAssetClass] = useState([]);
  const [issuerOptions, setIssuerOptions] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState([]);
  const [dealsOptions, setDealsOptions] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [rowsSelected, setRowsSelected] = useState(null);
  const [investorTab1, setInvestorTab1] = useState(false);
  const [investorTab2, setInvestorTab2] = useState(false);
  const [investorTab3, setInvestorTab3] = useState(false);
  const [investorTab4, setInvestorTab4] = useState(true);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const history = useHistory();
  const [displayData, setDisplayData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [selectedDealID, setSelectedDealID] = useState(null); // State for selected deal ID
  const [dateOptions, setDateOptions] = useState([]);
  const [Deals, setDeals] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const itemsPerBatch = 45;
  const inputRef = useRef(null);

  useEffect(() => {
    const component = window.location.pathname;
    sessionStorage.setItem("component", component);
  }, []);

  const fetchDefaultData = useCallback(async () => {
    const userId = sessionStorage.getItem("user_id");
    const userName = sessionStorage.getItem("user_name");

    if (!userId || !userName) {
      console.error("User data is missing from sessionStorage");
      return;
    }

    const data = { UserId: userId, UserName: userName };
    setScreenLoader(true);
    setLoading(true);

    try {
      const APIResponse = await GetDataByDefaultValue(data);
      if (APIResponse.status === 200) {
        const { asset_class, issuer_group, deal_type, start_date, end_date } =
          APIResponse.data.result;

        const assetClassOptions = asset_class.map((option) => ({
          value: option,
          label: option,
        }));
        const dealsArray = deal_type || [];

        setAssetClassOptions(assetClassOptions);
        setSelectedAssetClass(assetClassOptions); // Store asset class for next API call
        setStartDate(start_date);
        setEndDate(end_date);
        setDeals(dealsArray);

        // Trigger the next API call
        fetchUpdatedAssetData(assetClassOptions); // Pass asset class options to fetchUpdatedAssetData
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUpdatedAssetData = useCallback(
    async (assetClass = selectedAssetClass) => {
      const userName = sessionStorage.getItem("user_name");
      const data = {
        UserName: userName,
        asset_class: assetClass.map((item) => item.value),
      };

      setGetLoansLoader(true);
      setLoading(true);

      try {
        const APIResponse = await GetDataByUpdateAsset(data);
        if (APIResponse.status === 200) {
          const issuerArray = APIResponse.data.result.issuer_group;
          const issuerOptions = issuerArray.map((option) => ({
            value: option,
            label: option,
          }));
          setIssuerOptions(issuerOptions);

          // Set selectedIssuer to the first option in issuerOptions if it’s not already set
          const newSelectedIssuer =
            selectedIssuer.length > 0 ? selectedIssuer : issuerOptions;

          setSelectedIssuer(newSelectedIssuer);

          // Call fetchUpdatedIssuerData with newSelectedIssuer after setting it
          fetchUpdatedIssuerData(newSelectedIssuer);
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error("Error updating asset data:", error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setGetLoansLoader(false);
        setLoading(false);
      }
    },
    [selectedAssetClass]
  );

  const fetchUpdatedIssuerData = useCallback(
    async (issuerGroup) => {
      // console.log({ issuerGroup });

      const userName = sessionStorage.getItem("user_name");
      const data = {
        UserName: userName,
        issuer_group: issuerGroup.map((item) => item.value),
      };

      setGetLoansLoader(true);
      setLoading(true);

      try {
        const APIResponse = await GetDataByUpdateIssuer(data);
        if (APIResponse.status === 200) {
          const dealsArray = APIResponse.data.result.deal_type;
          const dealsOptions = dealsArray.map((deal) => {
            const [key, value] = Object.entries(deal)[0];
            return { value: key, label: value };
          });

          // console.log({ dealsOptions });

          setDealsOptions(dealsOptions);
          // Set initial deal to either the first option or null if empty
          const initialSelectedDeal =
            dealsOptions.length > 0 ? dealsOptions[0] : [];
          setSelectedDeals(initialSelectedDeal ? [initialSelectedDeal] : null);
          setSelectedDealID(
            initialSelectedDeal ? initialSelectedDeal.value : null
          );
          // fetchUpdatedPaymentDates(selectedAssetClass, initialSelectedDeal);

          // Call fetchUpdatedLoansData even if initialSelectedDeal is null
          // fetchUpdatedLoansData(
          //   initialSelectedDeal ? initialSelectedDeal.value : null
          // );
          // Call fetchUpdatedLoansData when dealsOptions is empty
          if (dealsOptions.length === 0) {
            fetchUpdatedLoansData(null); // Call with null
          }
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error("Error updating issuer data:", error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setGetLoansLoader(false);
        setLoading(false);
      }
    },
    [] // No dependencies to avoid re-renders
  );
  const fetchUpdatedPaymentDates = useCallback(
    async (dealID = selectedDealID) => {
      // console.log({ selectedDealID }); // Log selected deal ID

      const data = {
        deal_id: dealID,
      };

      setGetLoansLoader(true);
      setLoading(true);

      try {
        const APIResponse = await GetDataByPaymentDatesDeal(data);
        if (APIResponse.status === 200) {
          const dateOptions =
            Array.isArray(APIResponse.data.payment_dates) &&
            APIResponse.data.payment_dates.length > 0
              ? APIResponse.data.payment_dates.map((date) => ({
                  value: date,
                  label: date,
                }))
              : [];

          // console.log(
          //   "dateOptions",
          //   dateOptions.length,
          //   APIResponse.data.payment_dates
          // );

          const startDateNeeded =
            dateOptions.length > 0
              ? dateOptions[dateOptions.length - 1].value
              : "";
          // console.log({ startDateNeeded });

          const endDateNeeded =
            dateOptions.length > 0 ? dateOptions[0].value : "";

          sessionStorage.setItem("startDateNeeded", startDateNeeded);
          sessionStorage.setItem("endDateNeeded", endDateNeeded);

          // Update state using functional component state hooks
          setStartDate(startDateNeeded);
          setEndDate(endDateNeeded);
          setDateOptions(dateOptions);
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error("Error fetching payment dates:", error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setGetLoansLoader(false);
        setLoading(false);
      }
    },
    [] // Add selectedDealID as a dependency
  );

  const fetchTableChartData = useCallback(async () => {
    const selectedDealsOriginal = (
      Array.isArray(selectedDeals) ? selectedDeals : []
    )
      .map((selectedDeal) => {
        if (!selectedDeal || !selectedDeal.value) return null;
        const originalDeal = Deals.find(
          (deal) => deal && Object.keys(deal)[0] === selectedDeal.value
        );
        return originalDeal || null;
      })
      .filter(Boolean);

    // console.log({ startDate });

    const getStartDate = sessionStorage.getItem("startDateNeeded");
    const getEndDate = sessionStorage.getItem("endDateNeeded");

    const data = {
      asset_class: (Array.isArray(selectedAssetClass) ? selectedAssetClass : [])
        .map((item) => item && item.value)
        .filter(Boolean),
      issuer_group: (Array.isArray(selectedIssuer) ? selectedIssuer : [])
        .map((item) => item && item.value)
        .filter(Boolean),
      deal_type: selectedDealsOriginal,
      start_date: getStartDate,
      end_date: getEndDate,
    };

    setScreenLoader(true);
    setLoading(true);
    setGetLoansLoader(true);

    try {
      const APIResponse = await GetDataByTableChartDataDeal(data);

      if (APIResponse.status === 200) {
        setGetLoansLoader(false);
        setScreenLoader(false);
        setIsDataLoaded(true);
        setSelectedLoanId(null);
        setHiddenColumns(!hiddenColumns);

        // Debugging log
        // console.log("Fetched Data:", APIResponse.data.result.table);

        // Clear existing session data (to ensure update)
        sessionStorage.removeItem("finalDataSummary");
        sessionStorage.removeItem("finalDataTableSummary");

        // Store updated data in session storage
        sessionStorage.setItem(
          "finalDataSummary",
          JSON.stringify(APIResponse.data.result)
        );
        sessionStorage.setItem(
          "finalDataTableSummary",
          JSON.stringify(APIResponse.data.result.table)
        );

        // console.log(
        //   "Updated sessionStorage - finalDataTableSummary:",
        //   sessionStorage.getItem("finalDataTableSummary")
        // );
      } else {
        setGetLoansLoader(false);
        setLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setGetLoansLoader(false);
      setLoading(false);
      enqueueSnackbar("An error occurred while fetching data", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [
    selectedDeals,
    Deals,
    selectedAssetClass,
    selectedIssuer,
    startDate,
    endDate,
  ]);
  const fetchUpdatedLoansData = useCallback(
    async (dealID = selectedDealID) => {
      const data = { "Deal ID": dealID };

      setGetLoansLoader(true);
      setLoading(true);

      try {
        const APIResponse = await GetDataByUpdateLoans(data);

        if (APIResponse.status === 200) {
          const LoansData = APIResponse.data;
          // console.log({ LoansData });

          setTableData(LoansData);
          setDisplayData(LoansData.slice(0, itemsPerBatch));
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error("Error updating loans data:", error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setGetLoansLoader(false);
        setLoading(false);
        setScreenLoader(false);
      }
    },
    [] // No dependencies
  );

  // useEffect to watch for changes to selectedDealID and call fetchUpdatedLoansData
  useEffect(() => {
    const fetchData = async () => {
      if (selectedDealID) {
        try {
          await fetchUpdatedPaymentDates(selectedDealID); // Call payment dates function
          await fetchTableChartData(); // Call table chart data function
          await fetchUpdatedLoansData(selectedDealID); // Call loans data function
        } catch (error) {
          console.error("Error in fetching data:", error);
          // Optionally handle errors or show notifications here
        }
      }
    };

    fetchData();
  }, [selectedDealID]);

  // On initial load, call the default data function to start the sequence
  useEffect(() => {
    fetchDefaultData();
  }, [fetchDefaultData]);

  const fetchUpdatedLoansDeatilsData = useCallback(async () => {
    const dealID = selectedDealID;
    const selectedLoan = selectedLoanId;
    const data = { "Deal ID": dealID, "Loan ID": selectedLoan };

    setGetLoansLoader(true);
    setLoading(true);

    try {
      const APIResponse = await GetDataByUpdateLoansDetails(data);
      // console.log({ APIResponse });

      if (APIResponse.status === 200) {
        const LoanDetilsData = APIResponse.data;
        // console.log({ LoanDetilsData });
        setGraphData(LoanDetilsData);
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error("Error updating issuer data:", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setGetLoansLoader(false);
      setLoading(false);
      setScreenLoader(false);
    }
  }, [selectedLoanId]);

  const handleLoanClick = (loanId) => {
    // Check if the clicked Loan ID is the same as the selected one
    // console.log({ loanId, selectedLoanId });

    if (loanId === selectedLoanId) {
      // If same ID is clicked, toggle hiddenColumns and keep selectedLoanId
      setHiddenColumns(false);
      setSelectedLoanId(null);
    } else {
      // Update with a new selection and show all columns
      setSelectedLoanId(loanId);
      setHiddenColumns(true);
    }
  };

  // Assuming tableData is the data used to generate columns
  useEffect(() => {
    // Generate columns only once based on initial data or when the selectedLoanId changes
    setColumns(generateColumns(tableData, selectedLoanId));
  }, [tableData, selectedLoanId]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      setLoading(true);
      const dataToFilter = term
        ? tableData.filter((record) =>
            Object.values(record).some((value) =>
              value.toString().toLowerCase().includes(term)
            )
          )
        : tableData;

      setFilteredDatas(dataToFilter);
      setDisplayData(dataToFilter.slice(0, itemsPerBatch));
      setLoading(false);
    }, 300),
    [tableData]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const clearSearch = () => {
    setSearchTerm(""); // Clear the search term
    if (inputRef.current) {
      inputRef.current.focus(); // Re-focus on the input after clearing
    }
  };

  const generateColumns = (data, selectedLoanId) => {
    const record = data[0] || {}; // Use first data record as a sample for keys

    // Define the specific columns you want to display
    const requiredColumns = [
      "Loan ID",
      "Loan Status",
      "Current Principal Balance",
      "Current Interest Rate",
      "Maturity Date",
      "Purpose",
    ];

    return Object.keys(record)
      .filter((key) => requiredColumns.includes(key))
      .map((key) => ({
        title: key, // Optionally format the title
        dataIndex: key,
        key,
        width: 150,
        sorter: (a, b) => {
          const valueA = a[key];
          const valueB = b[key];
          // Custom sorting logic based on data type
          return isNaN(valueA) || isNaN(valueB)
            ? valueA.localeCompare(valueB) // Text sorting
            : parseFloat(valueA) - parseFloat(valueB); // Numeric sorting
        },
        render: (text, record) => {
          if (key === "Loan ID") {
            // console.log(
            //   "Selected Loan ID:",
            //   selectedLoanId,
            //   "Record Loan ID:",
            //   record["Loan ID"]
            // );
            return (
              <span
                onClick={() => handleLoanClick(record["Loan ID"])}
                style={{
                  color: "#018E82",
                  cursor: "pointer",
                  textDecoration:
                    selectedLoanId === record["Loan ID"] ? "underline" : "none",
                }}
              >
                {text}
              </span>
            );
          } else if (key === "Debt to Income Ratio (DIR)") {
            return (
              <span>
                <img
                  src={RedFlag}
                  alt="Red Flag"
                  style={{ marginRight: "4px", width: "16px" }}
                />
                {text}
              </span>
            );
          } else if (key === "Revolving Line Utilization Rate") {
            return (
              <span>
                <img
                  src={record.key === "1" ? ThumbUp : ThumbDown}
                  alt={record.key === "1" ? "Thumb Up" : "Thumb Down"}
                  style={{ width: "16px", marginRight: "4px" }}
                />
                {text}
              </span>
            );
          }
          return text; // Default render for other columns
        },
        ...(key === "Loan ID" && { fixed: "left" }), // Make "Loan ID" column fixed
      }));
  };

  // Track selectedLoanId changes
  useEffect(() => {
    if (selectedLoanId) {
      // console.log("Selected Loan ID has changed:", selectedLoanId);
      setSelectedLoanId(selectedLoanId);
      fetchUpdatedLoansDeatilsData();
    }
  }, [selectedLoanId]);

  // Scroll event handler
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth } =
      event.target;

    // Only proceed if we are at the bottom of the vertical scroll
    const isVerticalScrollBottom = scrollTop + clientHeight >= scrollHeight - 5;
    const isAtHorizontalStart = scrollLeft === 0; // Optional: Use this if you want to avoid loading on horizontal scrolls

    if (isVerticalScrollBottom && !loading && isAtHorizontalStart) {
      loadMoreData();
    }
  };

  // Load next batch of data
  const loadMoreData = () => {
    if (loading || displayData.length >= filteredDatas.length) return; // Stop if all data loaded

    setLoading(true); // Start loading
    setDisplayData((prevData) => [
      ...prevData,
      ...filteredDatas.slice(prevData.length, prevData.length + itemsPerBatch),
    ]);
    setLoading(false); // End loading
  };

  // const columns = generateColumns(tableData);

  const filteredColumns = columns.filter((col) => {
    if (col.dataIndex === "Loan ID") {
      return true; // Always show "Loan ID" column
    }
    if (hiddenColumns && selectedLoanId) {
      return false; // Hide all other columns when hiddenColumns is true
    }
    return true;
  });

  const handleBack = () => {
    setSelectedLoanId(null);
    setHiddenColumns(!hiddenColumns);
  };

  const renderCards = () => {
    const loanData = tableData.find(
      (loan) => loan["Loan ID"] === selectedLoanId
    );

    if (!loanData) return null;

    return (
      <div>
        <LoanDetailsAnalytics
          loanData={loanData}
          handleBack={handleBack}
          {...(graphData && { graphData })}
        />
      </div>
    );
  };

  const blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const handleClickSummary = () => {
    setInvestorTab1(true);
    setInvestorTab2(false);
    setInvestorTab3(false);
    setInvestorTab4(false);
    history.push("/investor/summaryAnalytics");
  };

  const handleClickStrats = () => {
    setInvestorTab1(false);
    setInvestorTab2(true);
    setInvestorTab3(false);
    setInvestorTab4(false);
    history.push("/investor/stratsAnalytics");
  };

  const handleClickPerformance = () => {
    setInvestorTab1(false);
    setInvestorTab2(false);
    setInvestorTab3(true);
    setInvestorTab4(false);
    history.push("/investor/performanceAnalytics");
  };

  const handleClickLoans = () => {
    setInvestorTab1(false);
    setInvestorTab2(false);
    setInvestorTab3(false);
    setInvestorTab4(true);
  };

  const handleSelectChange = (selectedOption) => {
    // Log the new value
    console.log("Updating selectedAssetClass:", selectedOption);
    setScreenLoader(true);

    const updatedSelectedAssetClass = selectedOption ? [selectedOption] : [];
    setSelectedAssetClass(updatedSelectedAssetClass);
    fetchUpdatedAssetData(updatedSelectedAssetClass);
  };

  const handleIssuerChange = (selectedOptions) => {
    setSelectedIssuer(selectedOptions);
    setScreenLoader(true);
    // Call fetchUpdatedIssuerData immediately after setting selectedIssuer
    fetchUpdatedIssuerData(selectedOptions);
  };

  const handleDealsChange = (selectedOption) => {
    // console.log({ selectedOption });

    setSelectedDeals([selectedOption]); // Update selected deal
    setSelectedDealID(selectedOption.value); // Update selected deal ID based on selection
    setScreenLoader(true);
    setIsDataLoaded(false);

    // Trigger additional actions if necessary, e.g., fetching data based on the new selected deal
    // setTimeout(async () => {
    //   await GetDataByTableChartDataDeal(selectedOption.value);
    // }, 1000); // Delay of 1 second (optional)
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "200px",
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid black",
      borderRadius: "8px",
      minHeight: "38px",
      boxShadow: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#adaeaa",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto",
      minWidth: "200px",
      zIndex: 9999,
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  return (
    <React.Fragment>
      <div className="">
        <Sidebar1 activeComponent={"Deal"} />
        <div className="content-bdb">
          <div className="header">{/* <Header></Header> */}</div>
          {screenLoader ? (
            <LinearLoader />
          ) : (
            <>
              <div className="page-contentofpool1">
                <div className="row1">
                  <div
                    className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "90rem",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <h3 className="headerdashboard-analytics">DEAL</h3>
                    </div>
                  </div>
                </div>

                <div className="FixTails">
                  <div className="dropdown-row">
                    <div className="dropdown-container">
                      <label className="dropdown-label">Asset Class:</label>
                      <div className="dropdown-content-topBar">
                        <Select
                          options={assetClassOptions}
                          placeholder="Asset Class"
                          value={selectedAssetClass}
                          onChange={handleSelectChange}
                          components={{ DropdownIndicator }}
                          styles={customStyles}
                        />
                      </div>
                    </div>

                    <div className="dropdown-container">
                      <label className="dropdown-label">Issuer Group:</label>
                      <div className="dropdown-content-topBar">
                        <MultiSelect
                          options={issuerOptions}
                          placeholder="Issuer"
                          value={selectedIssuer}
                          onChange={handleIssuerChange}
                          valueRenderer={customValueRenderer}
                          labelledBy="Select"
                          className="custom-multiselect multiselect-width"
                        />
                      </div>
                    </div>

                    <div className="dropdown-container">
                      <label className="dropdown-label">Deals:</label>
                      <div className="dropdown-content-topBar">
                        <Select
                          options={dealsOptions}
                          placeholder="Deal"
                          value={selectedDeals}
                          onChange={handleDealsChange}
                          components={{ DropdownIndicator }}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <DataDisplaySummary />

                <div className="SummaryStds">
                  <div className="col-7 col-sm-6 col-md-7 hellocard">
                    <button
                      type="button"
                      onClick={handleClickSummary}
                      className={
                        investorTab1
                          ? "issuerDashboard-table-top-button-insights-active-analytics"
                          : "issuerDashboard-table-top-button-insights-analytics"
                      }
                    >
                      Summary
                    </button>
                    <button
                      type="button"
                      onClick={handleClickStrats}
                      className={
                        investorTab2
                          ? "issuerDashboard-table-top-button-insights-active-analytics"
                          : "issuerDashboard-table-top-button-insights-analytics"
                      }
                    >
                      Strats
                    </button>
                    <button
                      type="button"
                      onClick={handleClickPerformance}
                      className={
                        investorTab3
                          ? "issuerDashboard-table-top-button-insights-active-analytics"
                          : "issuerDashboard-table-top-button-insights-analytics"
                      }
                    >
                      Performance
                    </button>
                    <button
                      type="button"
                      onClick={handleClickLoans}
                      className={
                        investorTab4
                          ? "issuerDashboard-table-top-button-insights-active-analytics"
                          : "issuerDashboard-table-top-button-insights-analytics"
                      }
                    >
                      Loans
                    </button>
                  </div>
                  <div className="IDA_Virtual">
                    {/* <Input
                      ref={inputRef}
                      placeholder="Search Loans..."
                      value={searchTerm}
                      onChange={handleSearch}
                      className="custom-input-loans"
                      style={{
                        marginBottom: 16,
                        marginLeft: 30,
                        width: 350,
                        border: "1px solid #000",
                      }}
                      // suffix={
                      //   searchTerm && (
                      //     <CloseOutlined
                      //       onClick={clearSearch}
                      //       style={{ cursor: "pointer", color: "#aaa" }}
                      //     />
                      //   )
                      // }
                    /> */}
                    <div className="IDA_Icons">
                      <img
                        src={IDA}
                        alt="VirtualAsst"
                        style={{ height: "38px", width: "38px" }}
                      />
                      <img
                        src={BETA}
                        alt="VirtualAsst"
                        className="beta"
                        style={{ height: "18px", width: "38px" }}
                      />
                    </div>
                    <ChatAssistant />
                  </div>
                </div>

                <div className="scrollable-content-loans">
                  <div
                    className="loan-table-alignments"
                    // style={{ flex: "0 0 200px" }}
                    // style={{ height: "400px", overflow: "auto" }}
                  >
                    <Table
                      columns={filteredColumns}
                      dataSource={displayData}
                      onScroll={handleScroll}
                      pagination={false} // Disable pagination for now
                      // pagination={{ pageSize: 10 }}
                      rowKey={(record) => record["Loan ID"]}
                      className="performance-table"
                      // style={{ tableLayout: "fixed" }} // Ensures fixed layout for table
                      scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
                      style={{
                        flexGrow: selectedLoanId ? 0.1 : 1, // Conditionally apply flex-grow
                        borderTopRightRadius: selectedLoanId ? "0px" : "10px",
                        borderBottomRightRadius: selectedLoanId
                          ? "0px"
                          : "10px",
                        width: selectedLoanId ? "150px" : "",
                        height: selectedLoanId ? "630px" : "",
                        overflowY: selectedLoanId ? "clip" : "",
                      }}
                    />

                    {hiddenColumns && selectedLoanId && (
                      <div style={{ flex: 1 }} className="loans-cards">
                        {renderCards()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(DealAnalyticsLoans);
