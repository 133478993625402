import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";

import store from "./store/index";
import { Provider } from "react-redux";
import ContextProvider from "./context/Context";

ReactDOM.render(
  <SnackbarProvider maxSnack={3}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </SnackbarProvider>,
  document.getElementById("root")
);

// document.addEventListener('contextmenu', function(e) {
//   e.preventDefault();
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
