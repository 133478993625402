import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import Performance from "./Performance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { fetchPerformanceFilters } from "../../../../../servies/services";

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

export default function PerformanceDropDowns({
  selectedDeals,
  startDate: propStartDate, // Rename the incoming startDate prop
  endDate: propEndDate,
}) {
  const [selectedPrePayments, setSelectedPrePayments] = useState([]);
  const [selectedDefaults, setSelectedDefaults] = useState([]);
  const [selectedLosses, setSelectedLosses] = useState([]);
  const [selectedCreditMetrics, setSelectedCreditMetrics] = useState([]);
  const [selectedDelinquencies, setSelectedDelinquencies] = useState([]);
  const [selectedCashflows, setSelectedCashflows] = useState([]);

  const [optionsPrePayments, setOptionsPrePayments] = useState([]);
  const [optionsDefaults, setOptionsDefaults] = useState([]);
  const [optionsLosses, setOptionsLosses] = useState([]);
  const [optionsCreditMetrics, setOptionsCreditMetrics] = useState([]);
  const [optionsDelinquencies, setOptionsDelinquencies] = useState([]);
  const [optionsCashflows, setOptionsCashflows] = useState([]);

  const [showDatepicker, setShowDatepicker] = useState(false);
  const [startDate, setStartDate] = useState(new Date(`${propStartDate}`));
  const [endDate, setEndDate] = useState(new Date(`${propEndDate}`));

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await fetchPerformanceFilters();
        const data = response.data.result;
        console.log(">>>>>", data);

        // Group the options by field_name
        const groupedOptions = data.reduce((acc, option) => {
          const { field_name, dis_name, field_value } = option;
          const mappedOption = { label: dis_name, value: field_value };

          if (!acc[field_name]) {
            acc[field_name] = [];
          }
          acc[field_name].push(mappedOption);

          return acc;
        }, {});

        // Set the options based on field_name
        const prePaymentsOptions = groupedOptions.PrePayments || [];
        const defaultsOptions = groupedOptions.Default || [];
        const lossesOptions = groupedOptions.Losses || [];
        const creditMetricsOptions = groupedOptions["Credit Metrics"] || [];
        const delinquenciesOptions = groupedOptions.Delinquencies || [];
        const cashflowsOptions = groupedOptions.Cashflows || [];

        // Set options state
        setOptionsPrePayments(prePaymentsOptions);
        setOptionsDefaults(defaultsOptions);
        setOptionsLosses(lossesOptions);
        setOptionsCreditMetrics(creditMetricsOptions);
        setOptionsDelinquencies(delinquenciesOptions);
        setOptionsCashflows(cashflowsOptions);

        // Set selected state to all options for each category
        setSelectedPrePayments(prePaymentsOptions);
        setSelectedDefaults(defaultsOptions);
      } catch (error) {
        console.error("Error fetching filter options:", error);
      }
    };

    fetchFilters();
  }, []);

  const toggleDatepicker = () => {
    setShowDatepicker(!showDatepicker);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(null); // Reset end date if it's before start date
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const applyDateRange = () => {
    setShowDatepicker(false);
  };

  const handlePrepayments = (selectedOptions) => {
    setSelectedPrePayments(selectedOptions);
  };

  const handleDefaults = (selectedOptions) => {
    setSelectedDefaults(selectedOptions);
  };

  const formatDate = (date) => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
  };

  return (
    <div>
      <div className="align-perf-dropdowns">
        <div className="date-range-picker">
          <h6>Date Range</h6>
          <div className="align-per-tabs">
            <div className="date-input-container">
              <input
                type="text"
                readOnly
                className="date-input"
                onClick={toggleDatepicker}
                placeholder="Select Date Range"
                value={
                  startDate && endDate
                    ? `${formatDate(startDate)} - ${formatDate(endDate)}`
                    : startDate
                    ? `${formatDate(startDate)}`
                    : endDate
                    ? `${formatDate(endDate)}`
                    : ""
                }
              />
              <FaRegCalendarAlt
                className="calendar-icon"
                onClick={toggleDatepicker}
              />
            </div>

            {showDatepicker && (
              <div className="datepicker">
                <span>Select the Date Range</span>
                <div className="datepicker-row" style={{ marginTop: "20px" }}>
                  <label className="datepicker-label">From:</label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Start Date"
                    className="date-picker"
                  />
                </div>
                <div className="datepicker-row">
                  <label className="datepicker-label">To:</label>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    showYearDropdown
                    dropdownMode="select"
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select End Date"
                    className="date-picker"
                  />
                </div>
                <div className="button-row">
                  <button className="daterange-btn" onClick={applyDateRange}>
                    Apply
                  </button>
                  <button
                    className="daterange-cancelbtn"
                    onClick={() => setShowDatepicker(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="align-perf-side-dropdowns">
          <div>
            <h6>Prepayments</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsPrePayments}
                value={selectedPrePayments}
                onChange={handlePrepayments}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Defaults</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsDefaults}
                value={selectedDefaults}
                onChange={handleDefaults}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Losses</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsLosses}
                value={selectedLosses}
                onChange={setSelectedLosses}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Credit Metrics</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsCreditMetrics}
                value={selectedCreditMetrics}
                onChange={setSelectedCreditMetrics}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Delinquencies</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsDelinquencies}
                value={selectedDelinquencies}
                onChange={setSelectedDelinquencies}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Cash Flows</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsCashflows}
                value={selectedCashflows}
                onChange={setSelectedCashflows}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
        </div>
      </div>
      <Performance
        selectedDeals={selectedDeals}
        startDate={startDate}
        endDate={endDate}
        selectedPrePayments={selectedPrePayments}
        selectedDefaults={selectedDefaults}
        selectedLosses={selectedLosses}
        selectedCreditMetrics={selectedCreditMetrics}
        selectedDelinquencies={selectedDelinquencies}
        selectedCashflows={selectedCashflows}
      />
    </div>
  );
}
