import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import "./DataDisplaySummary.css";

export default function DataDisplaySummary() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      try {
        const storedData = sessionStorage.getItem("finalDataTableSummary");
        setData(storedData ? JSON.parse(storedData) : []);
      } catch (error) {
        console.error("Error parsing JSON from sessionStorage:", error);
        setData([]);
      }
    };

    fetchData(); // Initial fetch

    const interval = setInterval(() => {
      fetchData();
    }, 500); // Poll every second (adjust interval as needed)

    return () => clearInterval(interval); // Clear interval on unmount
  }, []);

  useEffect(() => {
    let finalData;
    try {
      const storedData = sessionStorage.getItem("finalDataTableSummary");
      finalData = storedData ? JSON.parse(storedData) : [];
    } catch (error) {
      console.error("Error parsing JSON from sessionStorage:", error);
      finalData = [];
    }
    setData(finalData);
  }, []); // The empty array ensures this effect runs only once when the component mounts

  return (
    <div className="data-display">
      {data.map((item, index) => (
        <div key={index} className="data-item">
          <div className="data-value">
            {item.value}
            {item.percentage ? (
              <span className="percentage">
                {item.percentage.includes("-") ? (
                  <span className="negative-percentage">
                    <FontAwesomeIcon icon={faArrowDown} />
                    {item.percentage}
                  </span>
                ) : (
                  <span className="positive-percentage">
                    <FontAwesomeIcon icon={faArrowUp} />
                    {item.percentage}
                  </span>
                )}
              </span>
            ) : null}
          </div>
          <div className="data-label">{item.name}</div>
        </div>
      ))}
    </div>
  );
}
